// Download buttons
document.addEventListener("turbolinks:load", function () {
  $tables = $("[data-datatable-download-buttons]")
  downloadIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="23" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download align-middle me-2"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>'

  $tables.on('draw.dt', function () {
    $table = $(this);

    // Don't show download buttons when table has no data
    if ($table.DataTable().rows().count() === 0) {
      return;
    }

    $wrapper = $table.closest(`#${$table.attr('id')}_wrapper`)
    $dtFilter = $wrapper.find('.dataTables_filter')

    $dtFilter.find('.btn-group').remove();

    $btnGroup = $('<div class="btn-group ms-2">')

    $btnDropdown = $('<button class="btn btn-primary btn-sm btn-custom-download">')
      .html('Download').append(downloadIcon)
      .attr('data-bs-toggle', 'dropdown')
      .attr('aria-haspopup', 'true')
      .attr('aria-expanded', "false")

    $btnDropdownMenu = $('<div class="dropdown-menu">')

    $table.data('datatable-download-buttons').forEach(function (btnConfig) {
      $btn = $('<button class="btn btn-link dropdown-item cursor-point">').html(btnConfig.type.toUpperCase())
      $btn.on('click', function (e) {
        e.preventDefault();
        downloadFile(btnConfig);
      })
      $btnDropdownMenu.append($btn)
    })

    $btnGroup.append($btnDropdown)
    $btnGroup.append($btnDropdownMenu)

    $dtFilter.append($btnGroup)
  })
})

function downloadFile(config) {
  $.ajax(config.url, {
    type: 'Get',  // http method
    data: { date: $(".form-select").val(), for_download: true },
    success: function (data) {
      if (config.type == 'json') {
        data = JSON.stringify(data.data);
      }

      var blob = new Blob([data]);
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = config.download_name;
      link.click();
    },
    error: function (jqXhr) {
      if (jqXhr.status == 404) {
        window.location.href = jqXhr.responseJSON.url
      }
    }
  });
}