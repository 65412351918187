document.addEventListener("turbolinks:load", function () {
  $input = $("[data-behavior='autocomplete-input']")
  $container = $input.closest('.easy-autocomplete-search')

  var options = {
    getValue: "name",
    url: function () {
      inputText = $input.val();
      // console.log($input.val());
      return "/autocomplete.json?q=" + inputText;
    },
    categories: [
      {
        listLocation: "funds",
        header: "FUNDS"
      }, {
        listLocation: "fund_families",
        header: "FUND FAMILIES"
      }, {
        listLocation: "master_securities",
        header: "SECURITIES"
      }
    ],
    template: {
      type: "custom",
      method: function (value, item) {
        if (item.ticker && item.cusip && item.asset_category) {
          return item.name + "<div class='search-result-badges'><span class='ticker-search'>" + item.ticker + "</span> <span class='cusip-search'>" + item.cusip + "</span> <span class='category-search'>" + item.asset_category + "</span></div>";
        }
        else if (item.ticker && item.cusip) {
          return item.name + "<div class='search-result-badges'><span class='ticker-search'>" + item.ticker + "</span> <span class='cusip-search'>" + item.cusip + "</span></div>";
        }
        else if (item.ticker && item.asset_category) {
          return item.name + "<div class='search-result-badges'><span class='ticker-search'>" + item.ticker + "</span> <span class='category-search'>" + item.asset_category + "</span></div>";
        }
        else if (item.cusip && item.asset_category) {
          return item.name + "<div class='search-result-badges'><span class='cusip-search'>" + item.cusip + "</span> <span class='category-search'>" + item.asset_category + "</span></div>";
        }
        else {
          return item.name
        }
      }
    },
    list: {
      maxNumberOfElements: 12,
      onChooseEvent: function () {
        var url = $input.getSelectedItemData().url;
        Turbolinks.visit(url) // add after improve datatables
      }
    },
    highlightPhrase: false,
    // add request delay so when user hit "tes" it does not fire 3 requests with "t", "te", "tes"
    requestDelay: 500,
    // cssClasses: 'col', //https://stackoverflow.com/questions/48079613/width-override-of-easyautocomplete-plugin-inside-input-group-addon
    adjustWidth: false,
    ajaxSettings: {
      beforeSend: function () {
        $container.addClass('easy-autocomplete-loading')
      },
      complete: function () {
        $container.removeClass('easy-autocomplete-loading')
      }
    }
  }

  $input.easyAutocomplete(options)
})
