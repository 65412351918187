import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'submitButton', 'element', 'error', 'spinner']

  isStripe = () => this.element.className.includes('stripe-el')

  debug = (msg, extra = '') => {
    if (!this.config || !this.config.debug) return
    this.log(msg, extra)
  }

  log = (msg, extra = '') => {
    const id = this.element.id
    const pad = msg.length < 10 ? 10 - msg.length : 0
    console.log('Stripe', msg, ' '.repeat(pad), id, extra)
  }

  initialize() {
    const config_s = this.data.get('config')
    const config = config_s ? JSON.parse(config_s) : {}
    this.config = Object.assign({}, config)

    this.debug('initialize', { config: this.config })
  }

  async connect() {
    if (!this.isStripe()) return false;

    document.addEventListener('turbolinks:before-render', this._teardown)

    const {
      payment_intent,
      public_key
    } = this.config;

    const appearance = { theme: 'stripe' }
    const clientSecret = payment_intent;

    this.stripe = Stripe(public_key);
    this.elements = this.stripe.elements({ appearance, clientSecret, loader: 'always' });
    this.paymentElement = this.elements.create("payment");
    this.paymentElement.mount("#payment-element");

    this.debug('connect', { stripe: this })
  }

  _teardown = () => this.teardown()

  teardown(event) {
    document.removeEventListener('turbolinks:before-render', this._teardown)
    this.paymentElement.destroy()
    this.paymentElement = undefined

    this.debug('teardown')
  }

  async handleSubmit(event) {
    event.preventDefault();

    // Disable submit button
    this.submitButtonTarget.disabled = true;
    this.spinnerTarget.hidden = false

    const confirmParams = {
      return_url: this.config.return_url,
    }

    const { error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams,
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      this.errorTarget.textContent = error.message;
    } else {
      this.errorTarget.textContent = "An unexpected error occurred.";
    }

    // Enable submit button
    this.submitButtonTarget.disabled = false;
    this.spinnerTarget.hidden = true

  }
}