document.addEventListener("turbolinks:load", () => {
  window.followUser = function (t, slug) {
    const tokenCsrf = document.querySelector('[name=csrf-token]').content
    
    fetch(`/profile/${slug}`, 
      {
        method: 'POST',
        headers: {
          'X-CSRF-TOKEN': tokenCsrf,
          "Content-Type": "application/json"
        },
      }
    )
    .then((result) => {
      if(t.dataset.value == 0){
        t.dataset.value = 1
        t.text = 'Follow';
      }else{
        t.dataset.value = 0
        t.text = 'Unfollow';
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }
});