////////////////////////////////////////////////////////////////
// Warning: this file is NOT the same on the App Stack Theme. //
// Do not replace by the theme one on future updates.         //
////////////////////////////////////////////////////////////////

require("datatables.net-bs5");
require("datatables.net-buttons-bs5");
require("datatables.net-select-bs5");
require("datatables.net-fixedcolumns-bs5");

document.addEventListener("turbolinks:load", function () {
  // Datatables Responsive
  $.fn.DataTable.ext.pager.numbers_length = 5;
  $("table.standard-datatable").DataTable({
    responsive: true,
    order: [[1, "desc"]],
    pageLength: 25,
  });

  // DataTables needs to be refresh when loading inside tabs
  // https://datatables.net/examples/api/tabs_and_scrolling.html
  // It's is called twice on purpose...
  $('a[data-bs-toggle="tab"]').click(function (e) {
    $.fn.dataTable.tables({ visible: true, api: true }).columns.adjust();
    $.fn.dataTable.tables({ visible: true, api: true }).columns.adjust();
  });
});
