/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@rails/ujs").start();
require("turbolinks").start();
// require("@rails/activestorage").start();

import "bootstrap/dist/js/bootstrap";
import "../stylesheets/application.scss";
import "./app_stack_theme";

// export jquery to global
global.$ = require("jquery");

// Fontawesome Icons
import "@fortawesome/fontawesome-free/js/all";
import "@fortawesome/fontawesome-free/css/all";

// Feather Icons
const feather = require("feather-icons");
document.addEventListener("turbolinks:load", function () {
  feather.replace();
})

// font
require("typeface-poppins");

// Apex Charts
import ApexCharts from 'apexcharts'
window.ApexCharts = ApexCharts

// Easy-Autocomplete
import "easy-autocomplete/dist/jquery.easy-autocomplete";
import "../src/autoCompleteNavbarSearch"

// Stimulus
import "controllers"
